<template>

	<div class="userNews clearfix">
		<div class="userNewsLeft">
			<ul>
				<li :class="active==item.id?'active':''" v-for="(item,index) of cate" :key="index" @click="clickActive(item.id)">
					<span :class="item.number>0?'active':''">{{item.title}}</span></li>
			</ul>
		</div>
		<div class="userNewsRight">
			<template v-if="list.length>0">
				<div>
					<div :class="item.is_read == 1 ? 'userNewsRight_one userNewsRight_grey' : 'userNewsRight_one'"  v-for="(item,index) of list" :key="index" @click="toLookFun(item)">
						
						<div class="userNewsRight_one_thumb"><img :src="item.thumb" /></div>
						<div class="news_right"  >
							<div class="news_right_head">
								<h2>{{item.title}}</h2>
								<span>{{item.create_time.substring(0,10)}}</span>
							</div>
							<div class="news_right_desc">
								{{item.content}}
							</div>
						</div>
						
					</div>
				</div>
				<div class="page">
					<page v-if="total>12" :page="page" :limit="limit" :total="total" ref="page"></page>
				</div>
			</template>
			<template v-else>
				<div class="empty">
					<el-empty description="暂无数据"></el-empty>
				</div>

			</template>


		</div>

	</div>

</template>

<script>
	import page from '../../components/page.vue'
	export default {
		name: 'userNewsList',
		components: {
			page
		},
		data() {
			return {
				list: [],
				cate: [
					{id: 3,
						title: '作业消息'
					},
					{
						id: 1,
						title: '系统消息'
					},
					{
						id: 2,
						title: '活动消息'
					}
				],
				active: 3,
				// 分页
				total: 0, //总数
				page: 1, //页数
				limit: 12, //每页个数
				cateNumber:null
			}
		},
		mounted() {
			//console.log('type',this.$route.query.type)
			this.active=this.$route.query.type||3;
			this.$parent.notice_list=[];
			this.$parent.readNo=0;
			this.$parent.tab = 3;
			this.$parent.tabIndex = 1;
			this.$parent.$parent.routerIndex = 0;
			this.$parent.$parent.showIndex = 1;
			this.cateNumberFun();
		},
		methods: {
			listFun() {
				var that = this;
				if (this.$refs.page && this.page == 1) {
					this.page = 1
					this.$refs.page.currentPage = 1;
				}
				this.$api.POST(this.$face.noticeIndex, {
					type: this.active,
					pc:1,
					page: this.page,
					limit: this.limit
				}, function(res) {
					that.list = res.data.list;
					that.total = res.data.total;
					that.$parent.newsFun();
				})
			},
			cateNumberFun() {
				this.$api.POST(this.$face.noticeCateNumber, {}, (res) => {
					this.cateNumber=res.data;
					for(let i of this.cate){
						for(let i2 of this.cateNumber){
							if(i.id==i2.id){
								i.number=i2.count;
							}
						}
					}
				})
			},
			
			clickActive(id) {
				// if(this.wid){
				// 	this.active = this.wid;
				// 	console.log("wid",this.wid)
				// }
				this.active = id;
				this.page = 1;
				this.listFun();
			},

			//查看详情
			toLookFun(row){
				if(row.is_read==1){
this.jump(row);
				}else{
this.$api.POST(this.$face.noticeReadone, {
					id: row.id
				}, (res)=>{
					if(res.code==200){
						row.is_read=1;
						this.cateNumberFun();
						this.jump(row);
					}
				})
				}
				
			},
			jump(row){
				if(row.link_type == 1){
					this.$router.push({name:'refresh',query:{usernext:'user',refresh:true,query:{userId:row.work_id}}})
				}else if(row.link_type == 2){
					localStorage.setItem('workuser',row.user_id)
					var query = {
						id:row.work_id
					}
					this.$router.push({name:'refresh',query:{usernext:'videoDetail',refresh:true,query:query}})
				}else if(row.link_type == 3){
					//this.$router.push({name:'refresh',query:{usernext:'userWork',refresh:true,query:{type:3}}}) 
				}else if(row.link_type == 4){
					this.$router.push({name:'userInfo'})
				}else if(row.link_type == 5){
					this.$router.push({name:'userOrder'})
				}else if(row.link_type == 6){
					this.$router.push({name:'userDaily',query:{id:row.user_id}})
				}else if(row.link_type == 7){
					this.$router.push({name:'userCoupon'})
				}else if(row.link_type == 8){
					this.$router.push({name:'arrondiDetail',query:{type:1,id:row.work_id}})
				}
			}
		},
		watch: {
			'$route':'listFun'
		},
		activated() {
			this.$nextTick(() => {
				this.$parent.tab = 3;
				this.$parent.tabIndex = 1;
				this.$parent.$parent.routerIndex = 0;
				this.$parent.$parent.showIndex = 1;
				this.page = 1;
				if (this.$refs.page) {
					this.$refs.page.currentPage = 1;
				}
				this.listFun()
			});
		}
	}
</script>

<style scoped>
	.userNews {
		min-height: 400px;
		min-width: 1200px;
		max-width: 1400px;
		width: 72%;
		margin: 0 auto 39px;
		padding-top: 13px;
	}

	.userNewsLeft {
		/* min-width: 215px; */
		background-color: #fff;
		border-radius: 10px;
		width: 15%;
		float: left;
	}

	.userNewsLeft ul {
		padding: 37px 0 0;
		overflow: hidden
	}

	.userNewsLeft li {
		font-size: 16px;
		color: #333;
		padding-left: 37px;
		margin-bottom: 37px;
		cursor: pointer;
		height: 20px;
		line-height: 20px;
		position: relative;
	}

	.userNewsLeft li::before {
		display: block;
		width: 6px;
		height: 20px;
		content: ' ';
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 3px;
	}
	.userNewsLeft li span.active::after {
		display: block;
    width: 5px;
    height: 5px;
    content: ' ';
    background-color: #f00;
    position: absolute;
    left: 57%;
    top: 0;
    border-radius: 6px;
	}

	.userNewsLeft li.active::before {
		background-color: #5957ff;
	}

	.userNewsLeft li.active {
		color: #5957ff;
	}

	.userNewsRight {
		width: 79%;
		/* min-width: 1175px; */
		background: #fff;
		border-radius: 10px;
		float: right;
		padding: 15px 2% 0;
		min-height: 500px;

	}

	.userNewsRight_one {
		width: 96%;
		padding: 49px 2% 45px;
		border-bottom: 1px #eeeeee solid;
		display: flex;
		cursor: pointer;
	}
	.userNewsRight_one:hover{ background: #eee;}
	.userNewsRight_grey{
		opacity: 0.5;
	}

	/* .userNewsRight_one:last-child{border: none;} */
	.userNewsRight_one_thumb {
		float: left;
	}

	.userNewsRight_one_thumb img {
		width: 60px;
		height: 61px;
		margin-right: 22px;
		border-radius: 100%;
	}

	.news_right {
		float: left;
		flex: 1
	}

	.news_right_head {
		height: 20px;
		line-height: 20px;
		margin-top: 8px;
		margin-bottom: 8px
	}

	.news_right_head h2 {
		font-weight: bold;
		float: left;
		font-size: 16px;
		cursor: pointer;
	}

	.news_right_head span {
		float: right;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #999999;
	}

	.news_right_desc {
		font-size: 16px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #333333;
		line-height: 26px;
		width: 90%;
		white-space: pre-wrap;
	}

	.news_right_desc pre {
		word-wrap: break-word;
		white-space: pre-wrap;
		font-family: MicrosoftYaHei;
	}

	.page {
		padding: 20px 0;
	}

	.empty {
		font-size: 14px;
		color: #ccc;
		text-align: center;
		padding: 60px 0 40px;
	}
</style>
